.ArticlesOutletPage {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 16px;
  box-sizing: border-box;
}

.ComponentHeader {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.ReactSelect {
  position: initial !important;
  width: 400px!important;

}