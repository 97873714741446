@use './src/styles/colors';

.BlueButton {
  background-color: colors.$primary_blue;
  height: 48px;
  width: 100%;
  color: white;
  border-radius: 3px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /*display: inline-block;*/
  text-align: center;
  font-size: 18px;

  &:disabled {
    background-color: gray;
    cursor: default;
  }
  &:disabled:hover {
    background-color: gray;
  }
}

.BlueButton:hover {
  background-color: #16CBCA;
}

.RedButton {
  background-color: colors.$primary_red;
  height: 48px;
  width: 100%;
  color: white;
  border-radius: 3px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /*display: inline-block;*/
  text-align: center;
  font-size: 20px;
}

.RedButton:hover {
  background-color: #c03950;
}

.BlackButton {
  background-color: #161616;
  height: 48px;
  width: 100%;
  color: white;
  border-radius: 3px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
}

.BlackButton:hover {
  background-color: lighten($color: #161616, $amount: 20);
}
.GrayButton {
  background-color: #6c757d;
  height: 48px;
  width: 100%;
  color: white;
  border-radius: 3px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
}

.GrayButton:hover {
  background-color: lighten($color: #6c757d, $amount: 20);
}


.WhiteButton {
  background-color: white;
  height: 48px;
  width: 100%;
  font-size: 18px;
  color: #00B2B1;;
  border-radius: 3px;
  border: 1px solid colors.$grey_border;
  cursor: pointer;
  padding: 0.5rem 1rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.WhiteButton:hover,
.WhiteButton:focus {
  background-color: #FBFBFB;
  border: 1px solid #4F4F4F;
}

.Link {
  background-color: transparent;
  color: #5b6977ff;
  text-decoration: underline;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.DisabledButton {
  background-color: #888888;
  color: white;
  border-radius: 6px;
  font-weight: bold;
  border: none;
  cursor: auto;
  padding: 1rem;
  /*border: 1px solid #416caa;*/
}