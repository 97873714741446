@use "src/styles/colors";

.ColumnComponent {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;

  .Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    height: 100px;
    border-radius: 15px 15px 0 0;
    background-image: url("../../../assets/order-row-header.svg");
  }

  .Body {
    background-color: white;
    border: 1px solid colors.$grey_border;
    border-radius: 5px;
    overflow-y: hidden;
    height: 94%;
    background: linear-gradient(0deg, #FFF -9.89%, rgba(255, 255, 255, 0.00) 100%);
  }
}

.leftSideLabel {
  color: #FFF;
  text-align: right;
  font-size: 16px;
  font-weight: 700;
}