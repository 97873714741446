@use 'src/styles/colors';

.RFZOReminderPage {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 16px;
  box-sizing: border-box;
}

.ComponentHeader {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.DatePickerWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 12px 0;
  gap: 8px;
}

.DatePickerButtonStyle {
  padding: 12px;
  border-radius: 6px;
  background-color: colors.$primary_blue;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
}

.CalendarStyle {
  background-color: colors.$primary_blue;
  padding: 6px;
  border: none;
  border-radius: 6px;
  color: colors.$primary_blue;
}

.DatePickerButtonStyle:hover,
.DatePickerButtonStyle:focus {
  background-color: #16CBCA;
}

.DatePickerButtonStyle::placeholder {
  color: white;
}
