.pharmacyImagesAndStatus {
    background: linear-gradient(180deg, #04E1E0 -20.83%, #029B9A 68.75%), #FFF;
    height: 100%;
    padding: 0 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    overflow: hidden;
}

.coverImage {
    width: 100%;
    position: relative;
    border-radius: 30px 30px 0 0 !important;

    img {
        width: 100%;
        object-fit: cover;
    }
}

.pharmacyName {
    color: #FFF;
    height: 80px;
    font-size: 28px;
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}


.logoutButton {
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
}

.actionButton {
    margin: 15px 0;
}