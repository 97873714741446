@use "src/styles/colors";

.ConfirmationModal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 45;
  background-color: #FBFBFB;
  box-shadow: 0 0 1.2rem #acaaaa;
  width: 90%;
  max-width: 450px;
  border-radius: 5px;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    width: 95vw;
    padding: 15px;
  }

  display: flex;
  flex-direction: column;
  padding: 40px;
  color: #464A53;

  .ConfirmationModalHeader {
    display: flex;
    justify-content: space-between;
    position: relative;
    font-size: 22px;
    color: #161616;
    font-size: 18px;
    font-weight: 700;
  }

  .ConfirmationModalContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ConfirmationModalMessage {
    font-size: 16px;
    margin: 18px 0;
  }

  .XIcon {
    cursor: pointer;
  }

  .TextArea {
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    width: 100%;
    height: 50%;
    min-height: 200px;
    resize: none;
    border: 1px solid #DEDFE1;
    margin-top: 20px;
    padding:16px;
  }

  .TextArea:focus-visible{
    outline: none;
  }

  textarea::placeholder {
    color: #c7c8cb;
  }
}