@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body {
  margin: 0;
  font-family: 'Lexend', sans-serif;
  font-family: 'Inter', sans-serif;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(73deg, #04DFDE 0%, #7FDDBB 29.07%, #78D3C1 51.76%, #70C9C7 66.26%, #03ADAC 102.29%);
}

button {
  font-family: 'Lexend', sans-serif;
}

* {
  box-sizing: border-box;
}
