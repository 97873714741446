@use "src/styles/colors";

.RFZOTherapyRequestModal {
    background-color: #FFF;
    display: flex;
    flex-direction: column;


    .RFZOTherapyRequestModalHeader {
        border-bottom: 1px solid colors.$grey_border;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;
    }

    .RFZOTherapyRequestModalFooter {
        height: 100%;
        display: flex;
        align-items: end;
        justify-content: space-between;
    }

    .RFZOTherapyRequestModalContent {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .RFZOTherapyRequestModalMessage {
        font-size: 16px;
        margin: 18px 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .Column {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }

    .LeftColumn {
        box-sizing: border-box;
        width: 100%;
        // padding-right: 40px;
        height: 100%;
        border-right: 1px solid colors.$grey_border;
    }

    .FlexAlignCenter {
        display: flex;
        align-items: center;
    }

    .RightColumn {
        box-sizing: border-box;
        width: 100%;
        // padding-left: 40px;
        height: 100%;

        .RFZOTherapyRequestModalContent {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding-top: 20px;
            box-sizing: border-box;
        }

        .RightColumnContentWrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding-top: 20px;
        }

        .RFZOTherapyRequestActions {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }

    .XIcon {
        cursor: pointer;
    }
}

.TableCell {
    width: 45%;
}

.BoldData {
    font-weight: bold;
}

.noTherapyModal {
    min-height: 0 !important;
    max-width: 450px !important;
    width: fit-content !important;

    @media only screen and (max-width: 768px) {
        width: 90vw !important;
        max-width: 90vw !important;
        min-height: 0 !important;
    }

    .noTherapyModalButton {
        margin: 20px 0;
        height: 64px;
        font-size: 16px;
        width: 100% !important;
        width: fit-content
    }

    .explanation {
        color: #161616;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }
}