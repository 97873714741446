@use "src/styles/colors";

.AuthPageContent {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: colors.$background_color;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem 10% 0;
  z-index: 2;
  align-items: center;
  background: linear-gradient(73deg, #04DFDE 0%, #7FDDBB 29.07%, #78D3C1 51.76%, #70C9C7 66.26%, #03ADAC 102.29%);


  @media only screen and (max-width: 768px) {
    padding: 12px;
    justify-content: flex-start;
  }

}

.ContentWrapper {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 30px;
  align-items: flex-start;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }
}