@use 'src/styles/colors';

.editButton {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 100%;
}

.Modal {
    max-width: 400px;
}

.Icons {
    height: 12px !important;
    width: 12px !important;
}

.uploadWrapper {
    width: 100%;
    border-radius: 8px;
    overflow: auto;
    max-height: 70vh;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 10px));
    gap: 10px;
    margin: 20px 0;
}

.imageUploadField {
    aspect-ratio: 1;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    border: 1px solid #e9e9e9;
}

.uploadedImage {
    aspect-ratio: 1;
    object-fit: contain;
    border: 1px solid #e9e9e9;
    padding: 5px;
    position: relative;
}

.changeImageButton {
    position: absolute;
    width: 24px !important;
    padding: 0;
    height: 24px;
    top: 10px;
    right: 10px;
    display: flex;
    border-radius: 4px;
}