@use "src/styles/colors";

.contentWrap {
    padding: 30px 30px 40px;
    width: 100%;
    min-height: 600px;
    overflow: auto;
    max-height: 80vh;

    .header {
        color: #161616;
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
    }

    .smallHeader {
        color: #121010;
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .textFields {
        width: 48%;
    }

    .dateWrap {
        width: 48%;
    }
}

.datePickerWrapper {
    width: 100%;
    border: 1px solid #8F9AB7;
    border-radius: 15px;

    input {
        width: 100%;
        border-radius: 15px;
    }
}

.DatePickerButtonStyle {
    padding: 12px;
    border-radius: 6px;
    color: gray;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
}

.DatePickerButtonStyle::placeholder {
    font-size: 14px;
}

.note {
    width: 100%;
    height: 100px;
    border: 1px solid #8F9AB7 !important;
    background-color: #FFF;
    border-radius: 15px;
    padding: 15px;
    resize: none;

    &:focus {
        border: 1px solid #8F9AB7;
        outline: none;
    }
}

.selectList {
    border-radius: 15px;
    width: 60%;
    border: 1px solid #8F9AB7;

    :global {
        .itam-select__control {
            padding: 0 10px;
            border: none !important;
            border-radius: 15px;
        }
    }
}

.searchBar {
    border-radius: 15px;
    border: 1px solid #8F9AB7;
    height: 48px;
}

.addNewPatientButton {
    height: 32px !important;
    width: 250px !important;
    font-size: 14px;
    margin: 15px 0;
}


.modalButton {
    font-size: 14px;
    min-width: 200px;
    padding: 0 20px;
    flex: 1;
}


.openButton {
    border: none;
    font-size: 12px;
    background-color: transparent;
    width: fit-content;
    text-decoration: none;

    &:hover,
    &:focus {
        border: none;
        text-decoration: underline;
        background-color: transparent;
    }
}