.StatusComponent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-weight: bold;
  z-index: 100;
  background: linear-gradient(73deg, #04DFDE 0%, #7FDDBB 29.07%, #78D3C1 51.76%, #70C9C7 66.26%, #03ADAC 102.29%), #FBFBFB;

  @media only screen and (max-width: 768px) {
    display: block;
    inset: 0;
  }

  .XIcon {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 48px;
    margin-right: 48px;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
      margin-top: 0;
      margin-right: 0;
      top: 15px;
      right: 15px;
    }
  }

  .Title {
    font-size: 60px;
  }

  .PharmacyName {
    font-size: 25px;
  }

  .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 450px;
    max-width: 80vw;

    .Buttons {
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-top: 32px;
      gap: 16px;
    }
  }
}

.statusComponentStore {
  overflow: hidden;
  position: relative;
  background-color: #FFF;
  justify-content: space-between;
  max-height: 80%;
  display: flex;
  max-width: 1200px;
  width: 95%;
  border-radius: 30px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: block;
    overflow: auto;
    height: 100%;
    border-radius: 0;
    max-height: 90%;
    padding-bottom: 30px;
  }

  .columns {
    width: 33%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
}


.pharmacyDetailsWrap {
  overflow: auto;
  max-height: 95%;
  margin: 20px 0;
  padding: 0 10px;

  .header {
    color: #161616;
    font-family: Inter;
    font-size: 24px;
    font-weight: 800;
  }
}


.categoriesWrap {
  padding: 20px;


  .checkboxWrap {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 5px 0;
  }
}
