@use 'src/styles/colors';

.NotificationMessage {
  position: fixed;
  right: 45%;
  top: 2rem;
  background-color: colors.$primary_blue;
  border-radius: 6px;
  color: white;
  padding: 12px;
  animation: fadeOut 5s ease-in-out;
  z-index: 80;
}

@-webkit-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}
