.PrescriptionPhotos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin: 20px 0;
}

.permitWrap {
  height: 72px;
  font-size: 12px;
  width: 72px;
  border: 1px solid #00B2B1;
  border-radius: 10px;
  color: #8F9AB7;
  display: flex;
  align-items: center;
  justify-content: center;
}