@use 'src/styles/colors';

.UploadImageLib {
  width: 40%;
  height: auto;
  display: flex;
}

.UploadImageWrapper {
  width: 100%;
  height: auto;
  display: flex;
}

.ImageUploadField {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid colors.$grey_border;
  border-radius: 6px;
}

.ImageUploadField:hover {
  border: 1px solid colors.$primary_blue;
}

.UploadedImage {
  width: 100%;
  height: auto;
}