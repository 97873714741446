.StatusComponent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-weight: bold;
  z-index: 100;
  background: linear-gradient(73deg, #04DFDE 0%, #7FDDBB 29.07%, #78D3C1 51.76%, #70C9C7 66.26%, #03ADAC 102.29%), #FBFBFB;

  .XIcon {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 48px;
    margin-right: 48px;
    cursor: pointer;
  }

  .Title {
    font-size: 60px;
  }

  .PharmacyName {
    font-size: 25px;
  }

  .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 450px;
    max-width: 80vw;

    .Buttons {
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-top: 32px;
      gap: 16px;
    }
  }
}

.statusComponentStore {
  overflow: hidden;
  position: relative;
  background-color: #FFF;
  justify-content: space-between;
  max-height: 80%;
  display: flex;
  max-width: 1200px;
  width: 95%;
  border-radius: 30px;

  .columns {
    width: 33%;
  }
}


.pharmacyDetailsWrap {
  overflow: auto;
  max-height: 95%;
  margin: 20px 0;
  padding: 0 10px;

  .header {
    color: #161616;
    font-family: Inter;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 15px;
    position: sticky;
    top: 0px;
    z-index: 9;
    background-color: #FFF;
  }

  .subHeader {
    color: #161616;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    margin: 15px 0 5px;
  }

  .description {
    color: #838383;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
}


.pharmacyImagesAndStatus {
  background: linear-gradient(180deg, #04E1E0 -20.83%, #029B9A 68.75%), #FFF;
  height: 100%;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .imagesWrap {
    text-align: center;
  }

  .pharmacyStatus {
    .pharmacyName {
      font-size: 32px;
      color: #FFF;
      text-align: center;
      margin-bottom: 15px;
    }
  }
}

.categoriesWrap {
  padding: 20px;


  .checkboxWrap {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 5px 0;
  }
}

.dayLabel {
  padding: 0 5px;
}

.singleDay {
  margin-bottom: 10px;
}

.timeSeparator {
  color: #838383;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.singleDayTextField {
  margin-bottom: 0px !important;
}