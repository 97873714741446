.content {
    display: flex;
    overflow: hidden;
    max-width: 550px;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transform: translateZ(0) scale(1, 1);

    @media screen and (max-width: 576px) {
        flex-direction: column;
    }

    @media screen and (min-width: 577px) and (max-width: 767px) {
        max-width: 550px;
        flex-direction: column;
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
        max-width: 550px;
        flex-direction: row;
    }

    .collapseButton {
        z-index: 99999;
        background: #ffffff !important;
        border-radius: 15px !important;
        position: absolute;
        right: 10px;
        top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        height: 28px;
        width: 28px;
    }
}