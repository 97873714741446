.SimpleModal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  background-color: #FBFBFB;
  box-shadow: 0 0 1.2rem #acaaaa;
  min-height: 42vh;
  width: 50vw;
  border-radius: 5px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  color: #464A53;

  .XIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 14px;
    width: 14px;
  }
}