@use 'src/styles/colors';

table {
  width: 100%;
}
th, td {
  padding: 8px;
}

table, th, td {
  border: 1px solid #e2e8ec;
  border-collapse: collapse;
}

table tbody .Tr td:nth-child(1) {
  text-align: center;
}

table tbody .Tr:nth-child(odd) td{
  background-color: white;
}
table tbody .Tr:nth-child(even) td{
  background-color: #DDEBF7;
}

.ColumnFilter {
  border-radius: 3px;
  border: 1px solid colors.$grey_border;
  outline: none;
  padding: 4px;
}

.ColumnFilter:focus {
  border: 1px solid colors.$primary_blue;
}