@use 'src/styles/colors';

.ArticleItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .ArticleName {
      color: #161616;
      font-family: Inter;
      font-size: 13px;
      font-weight: 700;
      width: 65%;
    }

  .ArticleData {
    display: flex;
    font-size: 13px;
    width: 35%;
    align-items: center;
    gap: 3px;
    // padding: 0 6px;
    justify-content: flex-end;
  }

  .TrashIcon {
    stroke: #0A0C10;
  }
  .TrashIcon:hover {
    cursor: pointer;
  }
}