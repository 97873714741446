@use "src/styles/colors";

.Header {
  border-bottom: 1px solid colors.$grey_border;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
}

.ArticleName {
  font-weight: bold;
}

.FormStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  padding-top: 12px;
}

.FormBodyWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.FormRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.SubmitButton {
  display: flex;
  align-self: center;
  padding: 8px 12px;
  color: white;
  background-color: colors.$primary_blue;
  border: 1px solid colors.$primary_blue;
  border-radius: 3px;
  font-size: 20px;
}

.SubmitButton:hover {
  background-color: #16CBCA;
}

.XIcon {
  cursor: pointer;
}

.InputField {
  height: 2rem;
  width: 7rem;
  padding-left: .5rem;
  font-size: large;
  outline: none;
  border-radius: 6px;
  border: 1px solid colors.$grey_border;
}

.InputField:focus {
  border: 1px solid colors.$primary_blue;
  border-radius: 6px;
}

.contentWrap {
  padding: 40px;
  width: fit-content;
  // width: 450px;
}