@use 'src/styles/_colors';

.CommercialImages {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.SingleCommercialWeb {
  width: 1100px;
}

.SingleCommercialMobile {
  width: 1000px;
}

.CommercialPageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
