.SearchItem {
  display: flex;
  align-items: center;
  padding: 6px;
  cursor: pointer;
}

.SearchItem:hover {
  background-color: #E2E8EC;
}

.SearchItemText {
  text-align: left;
}