@use "src/styles/colors";

.OrderBox {
  border: 1px solid colors.$grey_border;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  //padding: 14px 24px;
  margin: 10px;
  cursor: pointer;

  .Line {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 1px solid colors.$grey_border;

    @media only screen and (max-width: 768px) {
      padding: 10px 10px;
    }
  }

  .Line:last-child {
    border-bottom: none;
  }

  .StatusProcessed {
    color: colors.$orange;
    font-weight: bold;
  }

  .StatusPrepared {
    color: colors.$primary_blue;
    font-weight: bold;
  }
}

.FlexAlignCenter {
  display: flex;
  align-items: center;
}



.orderBox {
  border: 1px solid colors.$grey_border;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
  background-color: #FFF;

  .cardHeader {
    display: flex;
    justify-content: space-between;
  }

  .timerAndStatus {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
  }

  .dataAndId {
    flex: 2;
  }

  .orderDate {
    color: #161616;
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .orderId {
    color: #161616;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .cardBody {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .rfzoAndPrice {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 700;
    }

    .userName {
      color: #161616;
      font-size: 18px;
      font-weight: 700;
    }
  }
}


.pricesWrap {
  padding: 0px 0px 20px;

  .priceItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;


    &:nth-child(1) {
      padding-top: 15px;
      border-top: 1px dotted #161616;
    }

    &:nth-child(3) {
      border-top: 1px dotted #161616;
      padding-top: 15px;
    }

    .label {
      color: #161616;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .value {
      color: #161616;
      text-align: right;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.deliveryNote {
  margin: 10px 0;

  .deliveryNoteLabel {
    display: block;
    margin-bottom: 10px;
  }
}

.detailsLabel {
  color: #161616;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-top: 10px;
}

.deliveryAddress {
  color: #161616;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration-line: underline;
}

.phoneNumber {
  color: #161616;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  text-decoration-line: underline;
}

.permitsUploadWrap {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;

  .fileLabel {
    padding: 10px;
    background-color: #fff;
    color: #00B2B1;
    font-size: 15px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #00B2B1;

    .fileLabelText {
      pointer-events: none;
    }
  }

  .permitUploadWrap {
    width: 100%;
    margin: 10px 0;
    border-top: 1px solid #DEDFE1;
    border-bottom: 1px solid #DEDFE1;
    padding: 10px 0;

    .permitInput {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }

    .permitLink {
      display: block;
      margin-bottom: 10px;
      font-weight: 700;
      color: #00B2B1;
    }

    .permitInputWrap {
      position: relative;
    }

    .uploadButton {
      height: 32px;
      font-weight: bold;
      padding: 0 20px;
      margin: 10px 0;
    }

  }
}

.timeCreated {
  color: #161616;
  font-size: 18px;
  font-weight: 700;
}

.confirmModalContent {
  padding: 40px;


  .header {
    color: #161616;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
  }

  button {
    margin: 20px auto 0;
    width: 200px;
  }
}