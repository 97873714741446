@use "src/styles/colors";

.Navbar {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 16px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 1rem;
  }

  li {
    display: flex;
    align-items: center;
    color: colors.$default_text_color;
    border: 1px solid colors.$grey_border;
    border-radius: 3px;
    height: 100%;
    a {
      text-decoration: none;
      color: inherit;
      height: 100%;
      padding: .5rem;
    }
  }

  li.active {
    height: 100%;
    background-color: colors.$primary_blue;
    color: white;
    border: 1px solid colors.$primary_blue;
  }

  li.active:hover {
    color: white;
  }

  li:hover {
    height: 100%;
    color: colors.$primary_blue;
    border: 1px solid colors.$primary_blue;
    display: flex;
    align-items: center;
  }
}