@use "src/styles/colors";

.ModalActions {
  display: flex;
  width: 100%;
  gap: 16px;
}

.Header {
  border-bottom: 1px solid colors.$grey_border;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
}

.DatePickerWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 12px 0;
  gap: 8px;
}

.DatePickerButtonStyle {
  padding: 12px;
  border-radius: 6px;
  background-color: colors.$primary_blue;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
}

.CalendarStyle {
  background-color: colors.$primary_blue;
  padding: 6px;
  border: none;
  border-radius: 6px;
  color: colors.$primary_blue;
}

.DatePickerButtonStyle:hover,
.DatePickerButtonStyle:focus {
  background-color: #16CBCA;
}

.DatePickerButtonStyle::placeholder {
  color: white;
}

.X:after {
  background-color: colors.$primary_blue !important;
  color: white !important;
  border: 1px solid white !important;
  font-size: 18px !important;
  height: 18px !important;
  width: 18px !important;
  padding: 0;
}

.X:hover:after {
  background-color: #16CBCA !important;
}

.NoteForUser {
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  border-radius: 3px;
  border: 1px solid colors.$grey_border;
  display: flex;
  height: 70px;
  padding: 5px;
  margin-bottom: 18px;
}

.TextArea {
  font-family: 'Lexend', sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
}

.TextArea:focus-visible {
  outline: none;
}

.XIcon:hover {
  cursor: pointer;
}


.calendarWrap {
  // max-width: 300px;
  width: 100%;
}

:global {
  .react-calendar__tile {
    padding: 10px;

    &:hover {
      background-color: #e9e9e9;
    }
  }

  .react-calendar__tile--now {
    background-color: #FFF;
    font-weight: 700;
  }


  .react-calendar__tile--hasActive,
  .react-calendar__tile--active {
    background-color: #FFF !important;
    color: #00B2B1;
    font-weight: 700;
  }
}


.header {
  color: #161616;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}