@use "src/styles/colors";

.OrderBox {
  border: 1px solid colors.$grey_border;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  //padding: 14px 24px;
  margin: 10px;
  cursor: pointer;

  .Line {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 1px solid colors.$grey_border;

    @media only screen and (max-width: 768px) {
      padding: 10px 10px;
    }
  }

  .Line:last-child {
    border-bottom: none;
  }

  .StatusProcessed {
    color: colors.$orange;
    font-weight: bold;
  }

  .StatusPrepared {
    color: colors.$primary_blue;
    font-weight: bold;
  }
}

.FlexAlignCenter {
  display: flex;
  align-items: center;
}



.orderBox {
  border: 1px solid colors.$grey_border;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
  background-color: #FFF;

  &.success {

    div,
    span {
      color: #00B1B0 !important;
    }
  }

  &.failed {

    div,
    span {
      color: #6c757d !important;
    }
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
  }

  .timerAndStatus {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
  }

  .dataAndId {
    flex: 2;
  }

  .orderDate {
    color: #161616;
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .orderId {
    color: #161616;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .cardBody {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .rfzoAndPrice {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 700;
    }

    .userName {
      color: #161616;
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.orderStatus {
  text-align: right;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.porudzbinaLabel {
  color: #161616;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}