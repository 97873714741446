@use "src/styles/colors";

.SocketModal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 45;
  background-color: #FBFBFB;
  box-shadow: 0 0 1.2rem #acaaaa;
  height: 42vh;
  width: 40vw;
  border-radius: 5px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  padding: 40px;
  color: #464A53;

  .SocketModalHeader {
    display: flex;
    justify-content: space-between;
    position: relative;
    font-size: 22px;
    padding-bottom: 8px;
    border-bottom: 1px solid colors.$grey_border;
  }

  .SocketModalContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .SocketModalMessage {
    font-size: 16px;
    margin: 18px 0;
  }

}