@use "src/styles/colors";

.PushNotificationsWrapper {
  width: 1200px;
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 6px;
  border: 1px solid colors.$grey_border;
  padding: 32px;
  gap: 16px;
  box-sizing: border-box;
  margin: 2rem auto;

  .PushNotificationsFormWrapper {
    width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: space-between;
    margin: auto;

    .PushNotificationsFormSection {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 3rem;
      padding: 1rem;
      width: 30%;
      max-height: 100%;
      box-sizing: border-box;
    }

    .ValidationErrorMessage {
      color: red;
      font-size: 14px;
    }

    .SendToAllLabel {
      height: 32px;
      display: flex;
      align-items: center;
    }

    .SendToAll {
      height: 16px;
      width: 16px;
    }

    input, select {
      box-sizing: border-box;
      height: 32px;
      width: 100%;
      border-radius: 6px;
      border: 1px solid colors.$grey_border;
      outline: none;
      padding-left: .5rem;
    }

    input:hover, select:hover, textarea:hover,
    input:focus, select:focus, textarea:focus {
      border: 1px solid colors.$primary_blue;
    }

    textarea {
      box-sizing: border-box;
      border-radius: 6px;
      border: 1px solid colors.$grey_border;
      outline: none;
      padding: 8px;
      resize: none;
    }
  }


}

