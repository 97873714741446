.progressBarWrap {
    position: relative;
    width: 54px;
    height: 54px;
    .barValue {
        position: absolute;
        width: 100%;
        top: calc(50% - 12px);
        color: #222;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
    }
}