.inputClass {
    border: 1px solid #8F9AB7;;
    background-color: #FFF;
    height: 48px;
    border-radius: 15px;
    padding: 0 10px;
    width: 100%;
    font-size: 14px;

    &.invalid {
        border: 1px solid red !important;
    }

    &:focus,
    &:hover {
        outline: none;
        border: 1px solid #B0B4BA;
    }
}

.inputWrap {
    position: relative;
    margin-bottom: 15px;
}

.passwordShowIcon  {
    position: absolute;
    right: 10px;
    top: calc(50% - 12px);
    cursor: pointer;
}

label {
    color: #838383 !important;
    margin: 0 !important;
    font-family: Inter;
    margin-bottom: 5px !important;
    font-size: 14px !important;
}