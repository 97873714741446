@use "src/styles/colors";

.Layout {
  position: relative;
  box-sizing: border-box;
  width: 100vw;
  min-height: 100vh;
  max-width: 100%;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: auto;

  @media only screen and (max-width: 768px) {
    padding: 12px;
  }
}