.statisticsWrap {
    width: 100%;

    .filtersIcon {
        text-align: right;
        padding: 20px;
        cursor: pointer;
    }


    tbody,
    table {
        display: block;
        width: 100%;
    }

    thead,
    tr {
        width: 100%;
        background-color: #FFF;
        display: flex;
    }

    th {
        color: #161616;
        font-size: 18px;
        font-weight: 700;
        padding: 20px 0;
        width: 33%;
        border-left: none;
        border-right: none;

        &:first-child {
            text-align: left;
            padding-left: 24px;
        }

        &:last-child {
            width: 34%;
        }

        @media only screen and (max-width: 768px) {
            font-size: 14px;
        }
    }

    tbody {
        min-height: 200px;
        max-height: calc(65vh - 80px);
        overflow: scroll;
        background-color: #FFF;
        width: 100%;
    }

    .noResults {
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        margin: auto;
    }

    td {
        background-color: #FFF;
        border: 0.5px solid #C9C9C9;
        text-align: center;
        height: 64px;
        padding: 0;
        line-height: 64px;

        @media only screen and (max-width: 768px) {
            font-size: 14px !important;
        }
    }

    .orderId,
    .totalPrice {
        color: #161616;
        font-weight: 700;
        width: 33%;
    }

    .orderId {
        font-size: 36px;
        text-align: left;
        padding-left: 24px;
    }

    .totalPrice {
        font-size: 24px;
    }

    .status {
        color: #00B1B0;
        font-size: 24px;
        font-weight: 700;
        width: 34%;

    }

    .footerWrap {
        background-color: #FFF;
        display: flex;
        border-radius: 0 0 15px 15px;

        .footerElements {
            width: 33%;
            padding: 20px;
            color: #161616;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;

            @media only screen and (max-width: 768px) {
                font-size: 12px;
            }

            &:first-child {
                text-align: left;
                padding-left: 24px;

            }

            &:last-child {
                width: 34%;

            }
        }
    }


    .table {
        width: 100%;
        margin: auto;
        border-radius: 15px 15px 0 0;
        overflow: hidden;
        max-height: 65vh;
    }
}



.filtersModal {
    display: block;
    padding: 20px;
    width: 350px;

    .header {
        color: #121010;
        font-size: 16px;
        font-weight: 700;
    }

    .statusFilter {
        padding: 20px;
        background-color: #FFF;
        border-radius: 15px;
        margin: 10px 0;
    }

    .selectItem {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
    }
}

.calendarWrap {
    width: 100%;
}

:global {

    .react-calendar {
        border: none !important;
    }

    .react-calendar__tile {
        padding: 10px;
        height: fit-content;

        &:hover {
            background-color: #e9e9e9;
        }
    }

    .react-calendar__tile--now {
        background-color: #FFF;
        font-weight: 700;
    }


    .react-calendar__tile--hasActive,
    .react-calendar__tile--active {
        background-color: #FFF !important;
        color: #00B2B1;
        font-weight: 700;
    }
}