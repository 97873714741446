@use "src/styles/colors";

.OrderModal {
    background-color: #FFF;
    display: flex;
    flex-direction: column;

    .OrderModalHeader {
        border-bottom: 1px solid colors.$grey_border;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;

        @media only screen and (max-width: 768px) {
            flex-wrap: wrap;
        }
    }

    .OrderModalFooter {
        margin-top: 10px;
        height: 100%;
        display: flex;
        align-items: end;
        justify-content: space-between;
    }

    .Items {
        border-top: 1px solid #161616;
        border-bottom: 1px solid #161616;
        margin: 20px 0 0;
    }
}

.FlexAlignCenter {
    display: flex;
    align-items: center;
}

.OrderModal .Column {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.OrderModal .LeftColumn {
    box-sizing: border-box;
    width: 100%;
    // padding-right: 40px;
    padding: 10px;
    border-right: 1px solid colors.$grey_border;


    // @media only screen and (max-width: 768px) {
    //     padding: 0;
    //     width: 100%;
    // }
}

.OrderModal .RightColumn {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    // padding-left: 40px;

    // @media only screen and (max-width: 768px) {
    //     padding: 0;
    //     width: 100%;
    // }

    .RightColumnContentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .UpperPart {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .RFZOInfoBlock {
        padding-top: 6px;
        display: flex;
        justify-content: space-between;
        gap: 8px;
    }

    .CopyLBO {
        fill: colors.$primary_blue;
        margin-left: 1rem;
        cursor: pointer;
    }

    .NoteFromUser {
        box-sizing: border-box;
        width: 100%;
        background-color: white;
        border-radius: 3px;
        border: 1px solid colors.$grey_border;
        display: flex;
        height: 100px;
        padding: 16px;
        margin-bottom: 12px;
    }

    .NoNoteFromUser {
        color: darkgray;
    }



    .OrderProcessingPart {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }



    .TimePicker {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        width: 100%;
        height: 100%;
        gap: 6px;
    }

    .TimeBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        background-color: colors.$secondary_blue;
        font-weight: bold;
        cursor: pointer;

        .Time {
            font-size: 43px;
        }
    }

    .TimeBox:hover {
        background-color: colors.$primary_blue;
        color: white;
    }

    .TextWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .LargeText {
        font-size: 26px;
        font-weight: bold;
    }

    .MediumText {
        font-weight: bold;
        margin: 16px 0 36px;
    }

    .MiddleText {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        font-weight: bold;
    }

    .BottomPart {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 40%;
    }
}

.OrderItem {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;

    .orderItemName {
        font-weight: 700;
        font-size: 14px;
        flex: 2;
    }

    .countAndPrice {
        flex: 1;
        font-size: 14px;
        text-align: right;
    }
}

.OrderItem:last-child {
    border-bottom: none;
}


.deliveryDetails {
    border-top: 1px solid #DEDFE1;
    border-bottom: 1px solid #DEDFE1;
    padding: 0px;

    div {
        margin: 10px 0;
    }
}

.permitsUploadWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .permitUploadWrap {
        width: 45%;
        margin-top: 30px;
        border: 1px solid #DEDFE1;
        padding: 10px;

        .permitInput {
            margin-bottom: 10px;
            width: 100%;
        }

        .permitLink {
            display: block;
            margin-bottom: 10px;
            font-weight: 700;
        }


        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        .uploadButton {
            height: 24px;
            background-color: #00B2B1;
            color: #FFF;
            font-weight: bold;
            padding: 0 20px;
            display: block;
            margin: 10px 0;
        }

        .label {
            font-size: 12px;
            margin-bottom: 10px;
        }
    }
}



.NoteForUser {
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    border-radius: 3px;
    border: 1px solid colors.$grey_border;
    display: flex;
    height: 90px;
    padding: 5px;
    margin-bottom: 12px;
    font-size: 14px;
}

.Label {
    display: inline-block;
    margin-top: 12px;
}

.TextArea {
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    resize: none;
    border: none;
}

.TextArea:focus-visible {
    outline: none;
}

.PrintActionsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 15px;
    margin: 20px 0;

    button {
        font-size: 12px !important;
        padding: 5px 10px;
        color: #00B2B1 !important;
        font-weight: 700;
        justify-content: center;
    }
}





.deliveryPersonDetails {
    color: #161616;

    .deliveryPersonDetailsHeader {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
    }

    .header {
        margin: 10px 0;
    }

    .infoItem {
        margin-bottom: 10px;
    }
}