.TableWrapper {
  width: 100%;

  table {
    width: 100%;
  }
  th, td {
    padding: 8px;
  }

  table, th, td {
    border: 1px solid #e2e8ec;
    border-collapse: collapse;
  }

  table tr td:nth-child(1) {
    text-align: center;
  }

  table tr:nth-child(odd) td{
   background-color: white;
  }
  table tr:nth-child(even) td{
    background-color: #DDEBF7;
  }




}