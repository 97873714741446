@use "src/styles/colors";

.Header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
}

.ActionsWrapper {
    display: flex;
    gap: 8px;
}

.UserIconBlue {
    fill: colors.$primary_blue;
    cursor: pointer;
}

.UserIconRed {
    fill: colors.$primary_red;
    cursor: pointer;
}

.FullScreen {
    display: flex;
    align-content: center;
}

.FullScreen:hover {
    cursor:pointer;
}

.RFZOMenu {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid colors.$grey_border;
    border-radius: 6px;
    padding: 0 12px;
    cursor: pointer;
}
.RFZOMenu:hover {
    background-color: colors.$grey_border;
}