.formWrap {
    width: 100%;
    height: 70vh;
    overflow: auto;
}

.fieldWrap {
    margin: 20px auto;
    width: 100%;

    label,
    input {
        display: block;
        width: 100%;
    }

    label {
        font-size: 16px;
        margin-bottom: 10px;
    }

    input, textarea {
        height: 48px;
        padding: 0 10px;
        border: 1px solid #e9e9e9;

        &:focus {
            border: 1px solid #b9b9b9;
            outline: none;
        }
    }
}

.errorDiv {
    font-size: 20px;
    color: red;
    text-align: center;
    margin: 20px 0 0;
}

label {
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
}