.PrescriptionPhoto {
    height: 100%;
    height: 72px;
    width: 72px;

    .linkWrap {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}