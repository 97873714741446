@use 'src/styles/colors';

.ArticleItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 10px;
  border-bottom: 1px solid #e9e9e9;
  padding: 5px;

  .ArticleName {
    width: 75%;
  }

  .ArticleData {
    display: flex;
    width: 35%;
    gap: 6px;
    padding: 0 6px;
    justify-content: flex-end;
  }

  .TrashIcon {
    stroke: colors.$primary_red;
  }
  .TrashIcon:hover {
    cursor: pointer;
  }
}