@use "src/styles/colors";

.SearchItems {
  background-color: white;
  /*border-radius: 2.188rem;*/
  display: flex;
  flex-direction: column;
  z-index: 21;
  box-shadow: 0 0 0.625rem -0.25rem #535358;
  max-height: 30vh;
  box-sizing: border-box;
  border-radius: 0 0 3px 3px;
  border-bottom: 1px solid #00B2B1;
  border-left: 1px solid #00B2B1;
  border-right: 1px solid #00B2B1;
  position: absolute;
  width: 100%;
}

.SearchBarInput {
  width: 100%;
  border: 1px solid colors.$grey_border;
  border-radius: 6px;
  height: 2.5rem;
  padding-left: 1rem;
  box-sizing: border-box;
}

.SearchBarInput:focus {
  border: 1px solid colors.$primary_blue !important;
  outline: none;
}

.SearchItemsDisplayNone {
  display: none;
}

.SearchBarBackdrop {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}