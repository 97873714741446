.FooterWrapper {
    padding-bottom: 2.5rem;
}

.Footer {
    position: absolute;
    box-sizing: border-box;
    padding: 0 2.5rem;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 2.5rem;
}