@use 'src/styles/colors';

.RFZOReminderPage {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 16px;
  box-sizing: border-box;
}

.ComponentHeader {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.DatePickerWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 12px 0;
  gap: 8px;
}

.DatePickerButtonStyle {
  padding: 12px;
  border-radius: 6px;
  background-color: colors.$primary_blue;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  width: 300px;
}

.CalendarStyle {
  background-color: colors.$primary_blue;
  padding: 6px;
  border: none;
  border-radius: 6px;
  color: colors.$primary_blue;
}

.DatePickerButtonStyle:hover,
.DatePickerButtonStyle:focus {
  background-color: #16CBCA;
}

.DatePickerButtonStyle::placeholder {
  color: white;
}


.buttonWrap {
  display: flex;
  gap: 5px;
  background-color: #FFF;
  padding: 10px;
  border-radius: 15px;
  padding: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.nameSearch {
  margin: 0 !important;
  min-width: 300px !important;
}

.successNotification {
  right: calc(50% - 150px);
  width: 300px;
  height: 60px;
  text-align: center;
  line-height: 36px;
  font-size: 20px;
  font-weight: 700;
}