@use "src/styles/colors";

.AddRFZOArticles {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  gap: 12px;
  margin-top: 38px;
}

.header {
  color: #161616;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
}