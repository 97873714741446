.PrintWrapper {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 0;
  width: 100vw;
  color: black!important;

  input {
    margin: 4px 0;
    border: 1px solid black;
    border-radius: 3px;
    font-size: 12px;
  }

  textarea {
    border: 1px solid black;
    border-radius: 3px;
    margin-bottom: 6px;
    font-size: 12px;
  }
  .ArticleName {
    font-weight: bold;
  }

}

@media print {
  header, footer, aside {
    display: none;
  }
}

@page  {
  margin: 0 1cm 2cm 1cm;
}