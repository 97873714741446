.tabsList {
    display: flex;
    border-radius: 89.448px;
    background: rgba(4, 205, 204, 0.48);
    width: 100%;
    height: 32px;
    justify-content: space-between;
    margin: 20px 0;

    .tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        flex: 1;
        color: #FFF;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 16.101px;
        border-radius: 15px;
        cursor: pointer;

        .tabCount {
            background-color: #FFF;
            border-radius: 100%;
            color: #00B2B1;
            font-size: 11px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
        }

        &:hover {
            background: #04CDCC;
        }

        &.selected {
            background: #04CDCC;

        }
    }
}