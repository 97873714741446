.modalButton {
    background-color: #00b2b1;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    width: 100%;
    padding: 20px;
    max-height: 80vh;
    overflow: auto;

    .header {
        color: #161616;
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        position: sticky;
        top: 0px;
        width: 100%;
        background-color: rgba(243, 246, 246);
    }

    .smallHeader {
        color: #121010;
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .calendarWrap {
        width: 100%;
    }

    .submitButton {
        position: sticky;
        bottom: 0px;
    }

    .groupItem {
        display: flex;
        gap: 5px;
        align-items: center;
        margin: 5px 0;
    }

    .clearFilters {
        display: inline-block;
        font-size: 14px;
        padding: 0;
        margin-left: 10px;
        line-height: 26px;
    }
}