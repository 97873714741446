.PrintWrapper {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 0;
  width: 100vw;
  color: black;
}

@media print {
  header, footer, aside {
    display: none;
  }
}

@page  {
  margin: 0 1cm 2cm 1cm;
}