@use "src/styles/colors";

.HeaderMenu {
  position: absolute;
  top: 2.5rem;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  border: 1px solid colors.$grey_border;
  background-color: white;
  padding: 0.8em;
  font-size: 1rem;
  border-radius: 10px;
  z-index: 25;
  width: 200px;
  box-sizing: border-box;
  left: 0;
}

.MenuItem {
color: colors.$default_text_color;
text-decoration: none;
}

.MenuItem:hover {
color: colors.$primary_blue;
}