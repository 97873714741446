@use "src/styles/colors";

.LoginForm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: calc(90% - 40px);
  min-width: inherit;
  margin: auto;
  height: 100%;


  @media only screen and (max-width: 768px) {
    width: 90%;
    height: fit-content;
  }
}

.InputFiledWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.InputFiled {
  height: 48px;
  padding: 8px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #8F9AB7;
}

.ValidationMessage {
  color: colors.$primary_red;
  height: 30px;
}

.loginPageWrap {
  width: 100%;
  max-width: 1200px;
  display: flex;
  max-height: 80vh;
  position: relative;


  @media only screen and (max-width: 768px) {
    max-height: fit-content;
    flex-direction: column;
  }

  .imageWrap {
    z-index: 2;
    width: 60%;
    border-radius: 32px;
    background: linear-gradient(180deg, #04E1E0 -20.83%, #029B9A 68.75%), #FFF;
    display: flex;
    img {
      aspect-ratio: 1;
      width: 100%;
    }

    @media only screen and (max-width: 768px) {
      z-index: 0;
      height: 40%;
      width: 100%;
      border-radius: 30px;
      overflow: hidden;
    }
  }

  .formWrap {
    position: absolute;
    height: 100%;
    right: 25px;
    width: 40%;
    background-color: white;
    padding: 20px;
    border-radius: 0 30px 30px 0;

    @media only screen and (max-width: 768px) {
      position: static;
      margin-top: -30px;
      width: 100%;
      padding: 50px 20px 0;
      height: 60%;
      border-radius: 0 0 30px 30px;
    }
  }
}
.header {
  color: #161616;
  width: 100%;
  font-family: Inter;
  font-size: 32px;
  font-weight: 800;
  text-align: left;
}

.subHeader {
  width: 100%;
  text-align: left;
  color: #161616;
  font-family: Inter;
  font-size: 16px;
  margin-bottom: 40px;
}