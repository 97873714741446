.SearchItems {
  overflow-y: auto;
  width: 100%;
}

.ItemActive {
  background: #d3d3d33b;
}

.Item:hover {
  cursor: pointer;
}

.SearchItemsDisplayNone {
  display: none;
}

.SearchItem {
  display: flex;
  align-items: center;
  padding: 6px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  border-bottom: 1px solid lightgray;

  &.selected {
    background-color: lightblue;
  }
}

.SearchItem:hover {
  background-color: #E2E8EC;
}

.SearchItemText {
  text-align: left;
}

.selectAll {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 100px;
  height: 32px;
  font-size: 12px;
}