@use "src/styles/colors";

.Message {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 5px 0;
}

.Message p {
  border-radius: 10px;
  line-height: 1.25;
  max-width: 100%;
  width: 100%;
  padding: 0.5rem .875rem;
  position: relative;
  word-wrap: break-word;
  margin: 0;
}

.Message p::before,
.Message p::after {
  content: "";
  position: absolute;
}

p.FromPharmacy {
  align-self: flex-end;
  color: #8F9AB7;
  background-color: #FFF;
  border: 2px solid #00B2B1;
}

p.FromPharmacy::before {
  position: absolute;
  border-bottom: 2px solid #00B2B1;
  border-right: 2px solid #00B2B1;
  background-color: #FFF;
  width: 16px;
  height: 16px;
  bottom: -10px;
  border-bottom-right-radius: 2px;
  right: 15%;
  transform: rotate(45deg);
}

p[class^="from-"] {
  margin: 0.5rem 0;
  width: fit-content;
}

p.FromPharmacy ~ p.FromPharmacy {
  margin: 0.25rem 0 0;
}

p.FromPharmacy ~ p.FromPharmacy:not(:last-child) {
  margin: 0.25rem 0 0;
}

p.FromPharmacy ~ p.FromPharmacy:last-child {
  margin-bottom: 0.5rem;
}

p.FromUser {
  color: #8F9AB7;
  background-color: #FFF;
  border: 1px solid #8F9AB7;
}

p.FromUser:before {
  position: absolute;
  border-bottom: 1px solid #8F9AB7;
  border-right: 1px solid #8F9AB7;
  background-color: #FFF;
  width: 16px;
  height: 16px;
  top: -9px;
  left: 15%;
  transform: rotate(225deg);
}

.NoTail::before {
  display: none;
}