@use 'src/styles/colors';

.Modal {
  width: 1200px;
  height: fit-content;
}

.ModalTitle {
  font-weight: 700;
  font-size: 18px;
}

.UploadFormWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding: 2rem;

  .Details {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  .Form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;

    .OptionsWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .Option {
      width: 100%;
      height: 32px;
      border-radius: 6px;
      border: 1px solid colors.$grey_border;
      outline: none;
    }

    .Option:focus, .Option:hover {
      border: 1px solid colors.$primary_blue;
    }

    .Option:disabled {
      border: none;
    }

    .Submit {
      width: 100%;
      color: white;
      background-color: colors.$primary_blue;
      border: 1px solid colors.$primary_blue;
      border-radius: 3px;
      padding: 8px;
      font-size: 18px;
      font-family: 'Lexend', sans-serif;
      flex: 50;
      cursor: pointer;
    }

    .SubmitDisabled {
      background-color: colors.$grey_border;
      border: 1px solid colors.$grey_border;
    }
  }
}

.ActionsWrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  margin-top: 12px;
  cursor: pointer;

  button {
    flex: 1;
  }

  .IconStyle {
    stroke: white;
  }
}