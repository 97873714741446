.RightSideHeader {
  display: flex;
  justify-content: flex-end;
  width: 75%;
  gap: 8px;
  align-items: center;
  span {
    font-size: 12px;
  }

  .RightSideHeaderTimer {
    font-weight: bold;
  }
}
