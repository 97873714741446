@use "src/styles/colors";

.searchWrap {
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 40vh;
}

.SearchItems {
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 21;
  max-height: 40vh;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid colors.$grey_border;
}

.SearchBarInput {
  width: 100%;
  border: 1px solid colors.$grey_border;
  height: 2.5rem;
  box-sizing: border-box;
  padding: 0 12px;
}

.SearchBarInput:focus {
  border: 1px solid colors.$primary_blue !important;
  outline: none;
}

.SearchItemsDisplayNone {
  display: none;
}

.SearchBarBackdrop {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}