.OrderProcessingPage {
  height: 78vh;
  width: 100%;
  display: flex;
  gap: 1rem;
  overflow-y:hidden;
  flex: auto;

  @media only screen and (max-width: 992px) {
    height: fit-content;
    flex-direction: column;
    overflow-y: auto;
  }
}