@use "src/styles/colors";

.modalClass {
  min-height: 0px !important;


  @media only screen and (max-width: 768px) {
    max-width: 100vw !important;
    width: 95%;
    padding: 20px;
  }
}

.Header {
  border-bottom: 1px solid colors.$grey_border;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  color: #161616;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
}

.ArticleName {
  color: #161616;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
}

.FormStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  padding-top: 12px;
}

.FormBodyWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.FormRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0;
  justify-content: space-between;
}

.SubmitButton {
  align-self: center;
  padding: 8px 12px;
  color: white;
  background-color: colors.$primary_blue;
  border: 1px solid colors.$primary_blue;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
}

.SubmitButton:hover {
  background-color: #16CBCA;
}

.XIcon {
  cursor: pointer;
}

.InputField {
  height: 2rem;
  width: 6rem;
  font-size: large;
  text-align: center;
  outline: none;
  border-radius: 6px;
  border: 1px solid colors.$grey_border;
}

.InputField:focus {
  border: 1px solid colors.$primary_blue;
  border-radius: 6px;
}