.contentWrap {
    padding: 20px;
    width: 100%;

    .textField {
        width: 100%;


    }
    .textFieldInput {
        &.invalid {
            border: 1px solid red !important;
        }
    }

    .inputError {
        color: red;
        text-align: center;
        margin-bottom: 15px;
    }

    .header {
        color: #161616;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
    }

    .listWrap {

        margin: 30px 0 10px;
        padding: 0 10px;
        max-height: 50vh;
        overflow-y: auto;

        .listItem {
            display: flex;
            justify-content: space-between;
            margin: 5px 0;

            .listItemName {
                color: #161616;
                font-family: Inter;
                font-size: 16px;
                font-weight: 700;
            }

            button {
                background-color: transparent;
                border-radius: 100%;
                height: 32px;
                width: 32px;

                &:hover {
                    background-color: #e9e9e9;
                }
            }
        }
    }
}

.modalButton {
    font-size: 14px;
    min-width: 150px;
    padding: 0 20px;
    flex: 1;
}